import { css } from '@emotion/core'
import Bg from '../../images/background-ao-vivo.png'

export const inspired = css`
  position: relative;
  padding-top: 14rem;
  /* padding-bottom: 8rem; */
  background-color: #683EA2;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  background: url(${Bg}) no-repeat;
  background-size: cover;
  background-position: center;
`
export const title = css`
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  color: #F5FF00;
  margin-bottom: 16px;
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
  }
`

export const titleSection = css`
  font-size: 56px;
  line-height: 64px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 5rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  span {
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #F5FF00;
    font-family: 'Oswald', sans-serif;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    margin: 0 18px;
    margin-bottom: 24px;
  }
`

export const contentView = css`
  position: relative;
  /* padding: 36px 24px 36px 24px; */
  width: 100%;
  /* background: #FFFFFF; */
  /* box-shadow: 0px 6px 7px rgba(0, 0, 0, 0.2); */
  border-radius: 4px;
  cursor: default;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: left;
  transition: all 0.25s linear 0s;
  text-align: center;
  table {
    font-family: 'Montserrat', sans-serif;
    color: #363732;
    thead {
      border-bottom: 2px solid #dee2e6;
    }
    td {
      padding: .85rem;
      font-size: 14px;
      span {
        background: #64c5b1;
        color: #fff;
        padding: .25em .4em;
        border-radius: .25rem;
        font-size: 75%;
      }
    }
    th {
      padding: .85rem;
      color: #6c757d;
      font-size: 14px;
    }
    tr {
      border-bottom: 2px solid #dee2e6;
    }
  }
  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #026FC2;
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: 'Montserrat', sans-serif;
  }
  h3 {
    font-size: 18px;
    line-height: 27px;
    color: #363732;
    
    font-family: 'Barlow Semi Condensed', sans-serif;
  }
  img {
    width: 150px;
    text-align: center;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    width: 100%;
    margin: 0 18px
  }
  /* @media (min-width: 260px) and (max-width: 980px) {
    margin-bottom: 80px;
  } */
`

export const columns = css`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    width: 100%;
  }
`

export const column = css`
  flex: 1 0 18%; 
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  @media (min-width: 260px) and (max-width: 736px) {
    padding: 0;
  }
`
export const btn = css`
  width: 328px;
  height: 65px;
  border-radius: 4px;
  background: #026FC2;
  /* border: 1px solid #026FC2; */
  color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  display: flex;
  font-weight: bold;
  font-size: 18px; 
  line-height: 22px;
  font-family: 'Barlow Semi Condensed', sans-serif;
  cursor: pointer;
  outline: none;
  a {
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      margin-right: 8px;
      width: 16px;
    }
  }
  transition: 0.5s all ease;
  :hover {
    transition: 0.5s all ease;
    box-shadow: 0 15px 10px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.1);
  }
`
export const visionBox = css`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: box-shadow 0.5s ease;
  p {
    font-size: 24px;
    line-height: 120%;
    color: #C4EFFA;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
`

export const itemTable = css`
  max-width: 1022px;
  width: 100%;
  height: 74px;
  background: #fff;
  border-radius: 8px;
  align-items: center;
  display: flex;
  padding: 16px;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 32px;
  color: #333333;
  margin-bottom: 16px;
  div:nth-child(1) {
    margin-right: 60px;
  }
  div:nth-child(2) {
    margin-right: 44px;
  }
  div:nth-child(3) {
    margin-right: 84px;
  }
  div:nth-child(4) {
    margin-right: 66px;
  }
  div:nth-child(5) {
    margin-right: 40px;
  }
  div:nth-child(7) {
    margin-left: auto;
    background: #27C077;
    border-radius: 8px;
    color: #fff;
    font-size: 14px !important;
    line-height: 32px;
    padding: 8px;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
  }
`
export const tagsTable = css`
  max-width: 1022px;
  width: 100%;
  border-radius: 8px;
  align-items: center;
  display: flex;
  padding: 16px;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 32px;
  color: #fff;
  margin-bottom: 8px;
  div:nth-child(1) {
    display: flex;
    margin-right: 40px;
    img {
      margin-right: 8px;
    }
  }
  div:nth-child(2) {
    display: flex;
    margin-right: 40px;
    img {
      margin-right: 8px;
    }
  }
  div:nth-child(3) {
    display: flex;
    margin-right: 40px;
    img {
      margin-right: 8px;
    }
  }
  div:nth-child(4) {
    display: flex;
    margin-right: 40px;
    img {
      margin-right: 8px;
    }
  }
  div:nth-child(5) {
    display: flex;
    margin-right: 56px;
    img {
      margin-right: 8px;
    }
  }
  div:nth-child(6) {
    display: flex;
    img {
      margin-right: 8px;
    }
  }
`
export const videoPlay = css`
  display: flex;
  img {
    transition: 0.5s all ease;
  }
  @media (min-width: 260px) and (max-width: 736px) {
    flex-direction: column;
    padding: 12px;
    iframe {
      width: 100%;
    }
    div:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`